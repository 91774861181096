// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo_logoimg__PLmCR {\n  position: absolute;\n  top: 5%;\n  left: 5%;\n  z-index: 100;\n  width: 150px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/logo/logo.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,YAAY;EACZ,YAAY;AACd","sourcesContent":[".logoimg {\n  position: absolute;\n  top: 5%;\n  left: 5%;\n  z-index: 100;\n  width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoimg": "logo_logoimg__PLmCR"
};
export default ___CSS_LOADER_EXPORT___;
