// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/bg.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error_container__06fJB {\n  width: 100%;\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  text-align: center;\n  color: #343434;\n}\n\n.error_container__06fJB h1 {\n  font-size: 160px;\n  margin: 0;\n  font-weight: 900;\n  letter-spacing: 20px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center no-repeat;\n  -webkit-text-fill-color: transparent;\n  -webkit-background-clip: text;\n}\n\n.error_container__06fJB a {\n  text-decoration: none;\n  background: #0074d9aa;\n  color: #fff;\n  padding: 12px 24px;\n  display: inline-block;\n  border-radius: 25px;\n  font-size: 14px;\n  text-transform: uppercase;\n  transition: 0.4s;\n}\n\n.error_container__06fJB a:hover {\n  background: #0074d9;\n}\n", "",{"version":3,"sources":["webpack://./src/screens/Error/error.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;EAC3B,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,gBAAgB;EAChB,oBAAoB;EACpB,oEAAuD;EACvD,oCAAoC;EACpC,6BAA6B;AAC/B;;AAEA;EACE,qBAAqB;EACrB,qBAAqB;EACrB,WAAW;EACX,kBAAkB;EAClB,qBAAqB;EACrB,mBAAmB;EACnB,eAAe;EACf,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".container {\n  width: 100%;\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  text-align: center;\n  color: #343434;\n}\n\n.container h1 {\n  font-size: 160px;\n  margin: 0;\n  font-weight: 900;\n  letter-spacing: 20px;\n  background: url(\"../../images/bg.jpg\") center no-repeat;\n  -webkit-text-fill-color: transparent;\n  -webkit-background-clip: text;\n}\n\n.container a {\n  text-decoration: none;\n  background: #0074d9aa;\n  color: #fff;\n  padding: 12px 24px;\n  display: inline-block;\n  border-radius: 25px;\n  font-size: 14px;\n  text-transform: uppercase;\n  transition: 0.4s;\n}\n\n.container a:hover {\n  background: #0074d9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "error_container__06fJB"
};
export default ___CSS_LOADER_EXPORT___;
