import React from "react";
import "../welcome.css";
const Boximage = () => {
  return (
    <div className="boxImage">
      <div className="boxsImage">
        <img src="/images/photo2.jpg" className="img2" alt=""></img>
        <img src="/images/photo3.jpg" className="img3" alt=""></img>
        <img src="/images/photo1.jpg" className="img1" alt=""></img>
      </div>
    </div>
  );
};
export default Boximage;
