import React, { Component } from "react";
import styles from "./or.module.css";

const Or = ({ title , job }) => {
  return (
    <div className={styles.Or}>
    
    </div>
  );
};

export default Or;
