// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".media_photos__pSd9u {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.media_Img__IrVl1 {\n  background-color: transparent;\n  border-radius: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: 0.9s;\n  text-decoration: none;\n}\n\n#media_pic__wz1i8 {\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/screens/Authentication/components/media/media.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,6BAA6B;EAC7B,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".photos {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.Img {\n  background-color: transparent;\n  border-radius: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  transition: 0.9s;\n  text-decoration: none;\n}\n\n#pic {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"photos": "media_photos__pSd9u",
	"Img": "media_Img__IrVl1",
	"pic": "media_pic__wz1i8"
};
export default ___CSS_LOADER_EXPORT___;
