// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".smalldrop_tog__jtgPS {\n  width: 160px;\n  color: black;\n  background-color: #f2efef;\n  border: none;\n  font-weight: bold;\n}\n", "",{"version":3,"sources":["webpack://./src/screens/Authentication/homePage/components/Feature/smalldropdown/smalldrop.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".tog {\n  width: 160px;\n  color: black;\n  background-color: #f2efef;\n  border: none;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tog": "smalldrop_tog__jtgPS"
};
export default ___CSS_LOADER_EXPORT___;
