// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".admin_row__XL90z {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n}\n\n.admin_colump__KG4cs {\n  font-size: 25px;\n  font-weight: 600;\n  color: #fdfdfe;\n  text-shadow: 0px 0px 5px #0074d9, 0px 0px 5px #193185, 0px 0px 5px #0074d9,\n    0px 0px 10px #193185;\n}\n.admin_body__tKaye {\n  margin: 5%;\n  display: flex;\n  flex-direction: column;\n}\n.admin_title__Ovf8r {\n  font-size: 25px;\n  font-weight: 800;\n  color: #193185;\n}\n", "",{"version":3,"sources":["webpack://./src/screens/admin/admin.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd;wBACsB;AACxB;AACA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".row {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-around;\n}\n\n.colump {\n  font-size: 25px;\n  font-weight: 600;\n  color: #fdfdfe;\n  text-shadow: 0px 0px 5px #0074d9, 0px 0px 5px #193185, 0px 0px 5px #0074d9,\n    0px 0px 10px #193185;\n}\n.body {\n  margin: 5%;\n  display: flex;\n  flex-direction: column;\n}\n.title {\n  font-size: 25px;\n  font-weight: 800;\n  color: #193185;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "admin_row__XL90z",
	"colump": "admin_colump__KG4cs",
	"body": "admin_body__tKaye",
	"title": "admin_title__Ovf8r"
};
export default ___CSS_LOADER_EXPORT___;
