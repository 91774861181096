// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lodingButton_OutButton__XXhPC {\n  transition: 0.9s;\n\n  width: 100%;\n  height: 60px;\n  background-color: #fafafa;\n  border-radius: 10px;\n  border: 1px solid #0074d9;\n  color: #0074d9;\n  font-weight: bold;\n  margin-bottom: 10px;\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n}\n.lodingButton_pic__NX0t8 {\n  width: 50px;\n  height: 50px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/loadingArea/lodingButton.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;;EAEhB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,mBAAmB;EACnB,yBAAyB;EACzB,cAAc;EACd,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;AACX;AACA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".OutButton {\n  transition: 0.9s;\n\n  width: 100%;\n  height: 60px;\n  background-color: #fafafa;\n  border-radius: 10px;\n  border: 1px solid #0074d9;\n  color: #0074d9;\n  font-weight: bold;\n  margin-bottom: 10px;\n  text-align: center;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n}\n.pic {\n  width: 50px;\n  height: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"OutButton": "lodingButton_OutButton__XXhPC",
	"pic": "lodingButton_pic__NX0t8"
};
export default ___CSS_LOADER_EXPORT___;
