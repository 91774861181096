// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".drop_select__DycM4 {\n  width: 49%;\n  height: 50px;\n  display: flex;\n  justify-content: center;\n  border-color: #929292;\n}\nselect {\n  background-color: #fafafa;\n  color: #929292;\n  border-radius: 5px;\n  width: 100%;\n  cursor: pointer;\n  border-color: #d3d0d0;\n  padding-left: 10px;\n  padding-left: 10px;\n  font-size: 14px;\n}\n\n.drop_select__DycM4 option {\n  color: #0074d9;\n  background-color: #fafafa;\n  display: flex;\n  justify-content: center;\n}\n", "",{"version":3,"sources":["webpack://./src/screens/Authentication/components/drop/drop.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,qBAAqB;AACvB;AACA;EACE,yBAAyB;EACzB,cAAc;EACd,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".select {\n  width: 49%;\n  height: 50px;\n  display: flex;\n  justify-content: center;\n  border-color: #929292;\n}\nselect {\n  background-color: #fafafa;\n  color: #929292;\n  border-radius: 5px;\n  width: 100%;\n  cursor: pointer;\n  border-color: #d3d0d0;\n  padding-left: 10px;\n  padding-left: 10px;\n  font-size: 14px;\n}\n\n.select option {\n  color: #0074d9;\n  background-color: #fafafa;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "drop_select__DycM4"
};
export default ___CSS_LOADER_EXPORT___;
