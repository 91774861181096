// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".or_Or__qY1P9 {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n.or_line__Wc9nv {\n  width: 38%;\n  margin-right: 1%;\n  margin-left: 1%;\n  border-width: 0;\n  height: 2px;\n  background-color: #0074d9;\n}\n.or_line2__Mk1rB {\n  width: 38%;\n  margin-right: 1%;\n  margin-left: 1%;\n  border-width: 0;\n  height: 2px;\n  background-color: rgb(5, 5, 5);\n}\n.or_continue__tShNi {\n  margin-top: 5px;\n  text-align: center;\n  font-size: small;\n}\n", "",{"version":3,"sources":["webpack://./src/screens/company/comapnySignup/components/or/or.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,WAAW;EACX,yBAAyB;AAC3B;AACA;EACE,UAAU;EACV,gBAAgB;EAChB,eAAe;EACf,eAAe;EACf,WAAW;EACX,8BAA8B;AAChC;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".Or {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n.line {\n  width: 38%;\n  margin-right: 1%;\n  margin-left: 1%;\n  border-width: 0;\n  height: 2px;\n  background-color: #0074d9;\n}\n.line2 {\n  width: 38%;\n  margin-right: 1%;\n  margin-left: 1%;\n  border-width: 0;\n  height: 2px;\n  background-color: rgb(5, 5, 5);\n}\n.continue {\n  margin-top: 5px;\n  text-align: center;\n  font-size: small;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Or": "or_Or__qY1P9",
	"line": "or_line__Wc9nv",
	"line2": "or_line2__Mk1rB",
	"continue": "or_continue__tShNi"
};
export default ___CSS_LOADER_EXPORT___;
