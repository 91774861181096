import React from "react";
import  "../welcome.css";
const Text2 = () => {
  return (
    <div className="textBox2">
      <h2 className="text-box2">Follow Our Steps We Will Help You</h2>
    </div>
  );
};
export default Text2;
